import React, { useState } from "react";
import { Button, Typography, Snackbar, Alert } from "@mui/material";
import styles from "./coupon.module.css";
import { verifyCoupon } from "./FetchApi";
function CouponComponent({ couponData, setCouponData,appliedCoupon, setAppliedCoupon }) {
  const [couponCode, setCouponCode] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleCouponCodeChange = (e) => {
    setCouponCode(e.target.value);
  };

  const handleApplyCoupon = async () => {
    if (!couponCode.trim()) {
      showSnackbar("Please enter a coupon code", "warning");
      return;
    }

    try {
      // // Simulated coupon validation 
      // // In a real app, this would be an API call to validate the coupon
      // const mockCoupons = [
      //   { 
      //     code: "SAVE10", 
      //     discountType: "percentage", 
      //     discountValue: 10,
      //     description: "10% off on total order"
      //   },
      //   { 
      //     code: "FIRST50", 
      //     discountType: "fixed", 
      //     discountValue: 50,
      //     description: "Flat ₹50 off on first order"
      //   }
      // ];

      // const foundCoupon = mockCoupons.find(
      //   coupon => coupon.code.toUpperCase() === couponCode.toUpperCase()
      // );

      const couponData = {
        code:couponCode
      }
      const result = await verifyCoupon(couponData)
      const foundCoupon = result.data
      if (foundCoupon) {
        setAppliedCoupon(result.couponData);
        setCouponData(foundCoupon);
        showSnackbar(`Coupon ${foundCoupon.code} applied successfully!`, "success");
        setCouponCode("");
      } else {
        showSnackbar("Invalid coupon code", "error");
      }
    } catch (error) {
      showSnackbar("Error applying coupon", "error");
    }
  };

  const handleRemoveCoupon = () => {
    setAppliedCoupon(null);
    setCouponData({});
    showSnackbar("Coupon removed", "info");
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="space-y-4">
      <div className={styles.couponCodeDiv}>
        <input 
          placeholder="Enter coupon code" 
          value={couponCode}
          onChange={handleCouponCodeChange}
          disabled={appliedCoupon !== null}
          className="w-full p-2 border rounded-l-md"
        />
        {!appliedCoupon ? (
          <Button 
            variant="contained" 
            color="info" 
            size="medium" 
            onClick={handleApplyCoupon}
          >
            Apply
          </Button>
        ) : (
          <Button 
            variant="contained" 
            color="secondary" 
            size="medium" 
            onClick={handleRemoveCoupon}
          >
            Remove
          </Button>
        )}
      </div>

      {appliedCoupon && (
        <div className="bg-green-50 p-4 rounded-md">
          <Typography variant="subtitle1" className="font-semibold">
            Coupon Applied: {appliedCoupon?.code}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {appliedCoupon?.description}
          </Typography>
        </div>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbarSeverity} 
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default CouponComponent;