import axios from "axios";
// const apiURL = process.env.REACT_APP_API+'/';

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};
export const createOrder = async (mode, code) => {
  let carts = JSON.parse(localStorage.getItem("cart")|| '[]').sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  if(!carts.length){
    return 
  }
  let productArray = [];
  let productDetails = [];
  if (carts) {
    console.log("carts>>>>", carts);
    for (const cart of carts) {
      productArray.push(cart.id);
      productDetails.push(cart);
    }
  }
  try {
    let data = { productDetails, productArray, mode, code };
    let res = await axios.post(`/api/order/create-order`, data, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const paymentCallBack = async (data) => {
  try {
    let res = await axios.post(`/api/order/payment-callback`, data, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const ViewOrder = async (data) => {
  try {
    let res = await axios.post(`/api/order/view-order`, data, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createAddresses = async (data) => {
  try {
    let res = await axios.post(`/api/user/add-address`, data, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchAddress = async (data) => {
  try {
    let res = await axios.get(`/api/user/fetch-address`, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const setDefaultAddress = async (id) => {
  try {
    let res = await axios.get(
      `/api/user/set-default-address?id=${id}`,
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const verifyCoupon = async (fData)=>{
  try {

    let carts = JSON.parse(localStorage.getItem("cart")|| '[]').sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    if(!carts.length){
      return 
    }
    let productArray = [];
    let productDetails = [];
    if (carts) {
      console.log("carts>>>>", carts);
      for (const cart of carts) {
        productArray.push(cart.id);
        productDetails.push(cart);
      }
    }
    let data = { productDetails, productArray, code:fData.code}
    let res = await axios.post(
      `/api/coupons/apply-coupon`,
      data
      ,
      Headers()
    );
    return res.data;
  } catch (error) {
    console.log(error)
  }
}